import {
	Resend
} from 'resend';

const resend = new Resend('ksmsksmkdk');

export const sendToUser = (htmlString, fromEmail, toEmail, subject) => {
	resend.emails.send({
		from: fromEmail,
		to: toEmail,
		subject: subject,
		html: htmlString
	});
}